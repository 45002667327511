import request from '@/utils/request'

export const goodList = (data) => {
  return request({
    url: '/hp/goodList',
    method: 'post',
    data,
  })
}
export const columnList = (data) => {
  return request({
    url: '/hp/columnList',
    method: 'post',
    data,
  })
}
export const getAreaList = (data) => {
  return request({
    url: '/hp/getAreaList',
    method: 'get',
    data,
  })
}
export const detail = (data) => {
  return request({
    url: '/hp/detail?id=' + data.id,
    method: 'get',
    data,
  })
}
export const columnDetail = (data) => {
  return request({
    url: '/hp/columnDetail?id=' + data.id,
    method: 'get',
    data,
  })
}
