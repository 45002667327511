import { render, staticRenderFns } from "./App.vue?vue&type=template&id=639f3160&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=639f3160&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.2_css-loader@https+++registry.npmmirror.com+css-loader+-+css-loader-6.8.1.tg_bbqgi7k35aw6pjdgq4hrvpxqya/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports